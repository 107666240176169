import { useMutation } from "react-query";

import { registerAuthProvider } from "../../services/auth_providers";

const useRegisterAuthProvider = () => {
    const mutationFn = async (payload) => {
        return await registerAuthProvider(payload.data);
    }
    return useMutation({
        mutationFn: mutationFn,
        onMutate: (variables) => {
            variables.setIsProcessing(true);
            variables.setApiError(null);
        },
        onSuccess: (response, variables) => {
            variables.successCallback(response.data);
            variables.setIsProcessing(false);
        },
        onError: (error, variables) => {
            variables.setApiError(error.response.data.message);
            variables.setIsProcessing(false);
        },
        onSettled: (data, error, variables) => {
            variables.setIsProcessing(false);
        },
        retry: 0,
    });
};
export default useRegisterAuthProvider;