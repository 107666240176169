import React from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';

import { BsX } from "react-icons/bs";

import ExpiryNotificationView from "../views/modals/ExpiryNotificationView";

import { getModalData, isModalActive, closeModal } from "../../store/slices/modal";
import { getCurrentApplicantKey } from "../../store/slices/global";

const modalName = "EXPIRY_NOTIFICATION_MODAL";
const modalTitle = "Expiry Notification";
const modalClass = "expiry_notification";
const topupLvl = 0;
ReactModal.setAppElement('#root');
const ExpiryNotificationModal = () => {
    const dispatch = useDispatch();
    const currentApplicantKey = useSelector(getCurrentApplicantKey());

    const isModalVisible = useSelector(isModalActive(modalName));
    const modalData = useSelector(getModalData(modalName));
    const { closeOnEsc = true, closeOnOutsideClick = true, closeBtn = true } = modalData || {};

    const afterOpenModal = () => { };

    const onCloseModal = () => {
        modalData.setIsProcessing(false);
        dispatch(closeModal(modalName));
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible && !!modalData}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={closeOnEsc}
            shouldCloseOnOverlayClick={closeOnOutsideClick}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content ${modalData?.contentClass ?? ''}`}>
                {closeBtn &&
                    <button className="imm__modal_close_btn" onClick={onCloseModal}>
                        <BsX />
                    </button>
                }
                <ExpiryNotificationView
                    onClose={onCloseModal}
                    currentApplicantKey={currentApplicantKey}
                    documentType={modalData?.documentType ?? ''}
                    notifications={modalData?.notifications ?? []}
                    documentPayload={modalData?.documentPayload}
                    isProcessing={modalData?.isProcessing ?? false}
                    setIsProcessing={modalData?.setIsProcessing ?? (() => { })}
                    setApiError={modalData?.setApiError ?? (() => { })}
                />
            </div>
        </ReactModal>
    );
};

export default ExpiryNotificationModal;