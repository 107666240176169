class ClassesHelper {
    add(classList, className) {
        if (!classList.contains(className)) {
            classList.add(className);
        }
    }

    remove(classList, className) {
        if (classList.contains(className)) {
            classList.remove(className);
        }
    }

    toggle(classList, className) {
        classList.toggle(className);
    }

    contains(classList, className) {
        return classList.contains(className);
    }
}

const classesHelper = new ClassesHelper();
export default classesHelper;