import moment from "moment";

export const getCanadianDollarsString = (amount) => {
    let value = amount;

    value = value.replace(/[^0-9.]/g, '');
    value = value.replace(/\.(?=.*\.)/, '');
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (parts[1]) {
        parts[1] = parts[1].slice(0, 2);
    }
    value = parts.join('.');

    return value;
};

export const getDateString = (date, format) => {
    const month = moment.utc(date).get('month') + 1;
    const day = moment.utc(date).get('date');
    const year = moment.utc(date).get('year');

    if (format === 'yyyy-mm-dd') {
        return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    } else if (format === 'MMM DD-YYYY') {
        return moment.utc(date).format('MMM DD-YYYY');
    } else if (format === 'MMM DD, YYYY') {
        return moment.utc(date).format('MMM DD, YYYY');
    };

    // default format is 'mm/dd/yyyy'
    return `${month < 10 ? '0' : ''}${month}/${day < 10 ? '0' : ''}${day}/${year}`;
};

export const getPhoneNumberString = (phoneNumber) => {
    let value = phoneNumber.replace(/[^0-9]/g, '');

    if (value.length > 3) {
        value = value.slice(0, 3) + '-' + value.slice(3);
    }

    if (value.length > 7) {
        value = value.slice(0, 7) + '-' + value.slice(7);
    }

    return value;
};

export const getElipsisString = (text, length) => {
    return text.length > length ? `${text.substring(0, length)}...` : text;
};