import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { oldStages } from '../constants/auth_stages';

import AuthRoutes from './AuthRoutes';
import PrivateRoutes from './PrivateRoutes';
import ResultRoutes from './ResultRoutes';
// import SubscriptionRoutes from './SubscriptionRoutes';

// const CommingSoonPage = lazy(() => import('../components/pages/CommingSoon'));
const ErrorPage = lazy(() => import('../components/pages/Error'));
const HomePage = lazy(() => import('../components/pages/Home'));
const PaymentConfirmedPage = lazy(() => import('../components/pages/PaymentConfirmed'));
const QuestionnairePage = lazy(() => import('../components/pages/questionnaire/Questionnaire'));
const QuestionnaireDashboardPage = lazy(() => import('../components/pages/questionnaire/Dashboard'));
const QuestionnaireDisclaimerPage = lazy(() => import('../components/pages/questionnaire/Disclaimer'));
const BookConsultPage2 = lazy(() => import('../components/pages/BookConsult2'));
const NotificationsPage = lazy(() => import('../components/pages/Notifications'));
const AccountPage = lazy(() => import('../components/pages/Account'));
const StatusManagerDashboardPage = lazy(() => import('../components/pages/status_manager/Dashboard'));
const StatusManagerQuestionnairePage = lazy(() => import('../components/pages/status_manager/Questionnaire'));
const DrawsPage = lazy(() => import('../components/pages/Draws'));
const ManageConnectionsPage = lazy(() => import('../components/pages/ManageConnections'));
const AuthSuccessPage = lazy(() => import('../components/pages/AuthSuccess'));
const AuthFailurePage = lazy(() => import('../components/pages/AuthFailure'));
const Authentication = lazy(() => import('../components/older/Containers/Authentication'));
const Activation = lazy(() => import('../components/older/Containers/Activation'));
const Results = lazy(() => import('../components/older/Containers/Results'));

const NavigationRoutes = () => {
    return (
        <Routes>
            <Route element={<AuthRoutes />}>
                <Route path="/auth" element={<Authentication initialStage={window.location.search.includes("?pc=") ? oldStages.SIGNUP_INFO : oldStages.SIGNIN_INFO} />} />
                <Route path='/auth/:oauthProvider/success' element={<AuthSuccessPage />} />
                <Route path="/auth/:oauthProvider/failure" element={<AuthFailurePage initialStage={oldStages.SIGNIN_INFO} />} />
                <Route path="/activate" element={<Activation />} />
                <Route path="/reset-password" element={<Authentication initialStage={oldStages.RESET_PASSWORD} />} />
                <Route path="/password-changed" element={<Authentication initialStage={oldStages.SIGNIN_INFO} />} />
                <Route path="/set-password" element={<Authentication initialStage={oldStages.RESET_PASSWORD} />} />
            </Route>

            <Route element={<PrivateRoutes />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/questionnaire/dashboard" element={<QuestionnaireDashboardPage />} />
                <Route path="/book-consult" element={<BookConsultPage2 />} />
                <Route path="/payment-confirmed" element={<PaymentConfirmedPage />} />
                <Route path="/notifications" element={<NotificationsPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/status-manager" element={<StatusManagerDashboardPage />} />
                <Route path="/status-manager/questionnaire" element={<StatusManagerQuestionnairePage />} />
                <Route path="/draws" element={<DrawsPage />} />
                <Route path="/manage-connections" element={<ManageConnectionsPage />} />

                {/* <Route element={<SubscriptionRoutes />}> */}
                <Route path='/questionnaire/disclaimer' element={<QuestionnaireDisclaimerPage />} />
                <Route path='/questionnaire' element={<QuestionnairePage />} />
                {/* </Route> */}

                <Route element={<ResultRoutes />}>
                    <Route path="/results" element={<Results />} />
                </Route>
            </Route>

            <Route path="*" element={<ErrorPage />} />
        </Routes>
    )
};

export default NavigationRoutes;