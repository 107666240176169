import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { respondToOrganizationInvitation } from "../../services/invitations";

import { closeModal, openModal } from "../../store/slices/modal";

import useCopyStatusInfoFromOrganization from "../applicants/useCopyStatusInfoFromOrganization";
import useUpdateStatusInfoAtOrganizations from "../applicants/useUpdateStatusInfoAtOrganizations";

const useRespondToOrganizationInvitation = () => {
    const dispatch = useDispatch();

    const { mutate: copyStatusInfoFromOrganization } = useCopyStatusInfoFromOrganization();
    const { mutate: updateStatusInfoAtOrganizations } = useUpdateStatusInfoAtOrganizations();

    const mutationFn = async (data) => {
        return await respondToOrganizationInvitation(data.payload);
    };

    const queryClient = useQueryClient();

    return useMutation(mutationFn, {
        onMutate: (variables) => { },
        onSuccess: (response, variables) => {
            if (response?.data) {

                queryClient.invalidateQueries({ queryKey: ['connections'] });
                queryClient.invalidateQueries({ queryKey: ['new-invitations'] });

                if (variables.payload.status === 'accepted') {
                    const immigrationData = response.data;

                    let message = 'Please click on the button below to confirm that you are now employed.';
                    let btnArr = [
                        { label: 'Confirm', style: 'primary', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                    ];
                    if (
                        immigrationData.applicant_passports === 0 && immigrationData.applicant_permits === 0 && immigrationData.applicant_visas === 0 &&
                        (immigrationData.worker_passports > 0 || immigrationData.worker_permits > 0 || immigrationData.worker_visas > 0)
                    ) {
                        message = `Update status information based on information from your organization.`;
                        btnArr = [
                            {
                                label: 'Update Status',
                                style: 'primary',
                                onClick: () => {
                                    dispatch(closeModal('MESSAGE_MODAL'));
                                    copyStatusInfoFromOrganization({ organizationId: immigrationData.organization_id });
                                }
                            },
                            { label: 'No thanks!', style: 'info', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                        ];
                    } else if (immigrationData.applicant_passports > 0 || immigrationData.applicant_permits > 0 || immigrationData.applicant_visas > 0) {
                        message = `You have status information filled. Please click on "Update Status" to update your status information in employer portal.`;
                        btnArr = [
                            {
                                label: 'Update Status',
                                style: 'primary',
                                onClick: () => {
                                    dispatch(closeModal('MESSAGE_MODAL'));
                                    updateStatusInfoAtOrganizations({
                                        purpose: 'invitation_response',
                                        applicantKey: 'main'
                                    });
                                }
                            },
                            { label: 'No thanks!', style: 'info', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                        ];
                    }

                    dispatch(closeModal('ORGANIZATION_INVITATION_MODAL'));
                    dispatch(openModal({
                        name: 'MESSAGE_MODAL',
                        data: {
                            closeOnEsc: false,
                            closeOnOutsideClick: false,
                            icon: 'success',
                            title: 'Congratulations! You are now linked to the organization.',
                            titleColor: 'primary',
                            content: message,
                            buttons: btnArr
                        },
                    }));
                } else {
                    dispatch(closeModal('ORGANIZATION_INVITATION_MODAL'));
                    dispatch(openModal({
                        name: 'MESSAGE_MODAL',
                        data: {
                            closeOnEsc: true,
                            closeOnOutsideClick: true,
                            icon: 'error',
                            title: 'Invitation Refused',
                            titleColor: 'primary',
                            content: 'You have refused the invitation. We have notified the organization.',
                            buttons: [
                                { label: 'Close', style: 'info', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                            ]
                        },
                    }));
                }
            };
        },
        onError: (error, variables) => {
            variables.setApiError({ message: error.message, idx: variables.idx });
        },
        onSettled: (data, error, variables) => {
            variables.setIsProcessingFor({ response: null, idx: null });
        }
    });
};

export default useRespondToOrganizationInvitation;