import { createSlice } from "@reduxjs/toolkit";
import { produce } from 'immer';

const initialState = {
    activeModals: []
};

const modalSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        openModal: (state, action) => {
            const { name, data } = action.payload;
            const isModalActive = state.activeModals.findIndex(modal => modal.name === name) !== -1;

            return produce(state, draft => {
                if (!isModalActive) {
                    draft.activeModals.push({ name, data });
                }
            });
        },
        closeModal: (state, action) => {
            const name = action.payload;
            return produce(state, draft => {
                draft.activeModals = draft.activeModals.filter(modal => modal.name !== name);
            });
        },
        closeAllModals: (state) => {
            return produce(state, draft => {
                draft.activeModals = [];
            });
        }
    }
});

export const { openModal, closeModal, closeAllModals } = modalSlice.actions;
export default modalSlice.reducer;

// Selectors
export const getActiveModals = () => (state) => state.modals.activeModals;
export const isModalActive = (name) => (state) => {
    return state.modals.activeModals?.findIndex(modal => modal?.name === name) !== -1
};
export const getModalData = (name) => (state) => {
    return state.modals.activeModals?.find(modal => modal?.name === name)?.data ?? null
};