import api from ".";

import cookiesHelper from "../helpers/cookies";

export const getPassportRecords = async (applicantKey) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`passports/${applicantKey}`);
        return res.data?.data?.length ? res.data.data : [];
    } catch (error) {
        throw error?.response?.data;
    };
};

export const saveTravelDocs = async (applicantKey, payload) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`passports/${applicantKey}`, payload);
        return res.data;
    } catch (error) {
        throw error?.response?.data;
    };
};

export const deletePassportRecords = async (payload, applicantKey) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`passports/delete/${applicantKey}`, payload);
        return res.data;
    } catch (error) {
        throw error?.response?.data;
    };
};