import React from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';

import { BsX } from "react-icons/bs";

import { setCurrentApplicant } from "../../store/slices/global";
import { getModalData, isModalActive, closeModal } from "../../store/slices/modal";
import { getTrackingPath } from "../../helpers/tracking";

const modalName = "SPOUSE_ADDED_MESSAGE_MODAL";
const modalTitle = "Spouse Added Message";
const modalClass = "spouse_added_message";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const SpouseAddedMessage = () => {
    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));
    const modalData = useSelector(getModalData(modalName));

    const onCloseModal = () => {
        dispatch(closeModal(modalName));
    };

    const onSpouseQuestionnaire = () => {
        dispatch(setCurrentApplicant(modalData?.spouseData?.id));
        window.location.href = getTrackingPath('/questionnaire/dashboard');
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <div className={`imm__${modalClass}_inner_wrap`}>
                    <button className="close_btn" onClick={onCloseModal}>
                        <BsX />
                    </button>

                    <div className="imm__spouse_added__model_view">
                        <h3 className="color_primary text_center mt-3">{modalData?.title}</h3>
                        <br />

                        {
                            modalData?.content ?
                                <div className={`imm__${modalClass}__modal_content_sec`} dangerouslySetInnerHTML={{ __html: modalData.content }} />
                                : null
                        }
                        <button className="btn_block imm__regular__btn imm__btn__primary mt-3" onClick={onSpouseQuestionnaire}>Go to Spouse Questionnaire</button>
                        <button className="btn_block imm__regular__btn imm__btn__tertiary mt-3" onClick={onCloseModal}>Go Back</button>
                        <br />
                    </div>

                </div>
            </div>
        </ReactModal>
    );
};

export default SpouseAddedMessage;