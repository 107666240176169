import { Navigate, Outlet } from "react-router-dom";

import authHelper from "../helpers/auth";
import { getTrackingPath } from "../helpers/tracking";

const AuthRoutes = () => {
    const tokenValidationRes = authHelper.isAuthTokenValid();

    return (
        !tokenValidationRes.result
            ? <Outlet />
            : <Navigate to={getTrackingPath('/')} replace />
    );
};

export default AuthRoutes;