import { useMutation } from "react-query";

import { verifyAuthProviderCode } from "../../services/auth_providers";

const useVerifyAuthProviderCode = () => {
    const mutationFn = async (payload) => {
        return await verifyAuthProviderCode(payload.data);
    }
    return useMutation({
        mutationFn: mutationFn,
        onMutate: (variables) => {
            variables.setIsProcessing(true);
            variables.setApiError(null);
        },
        onSuccess: (data, variables) => {
            variables.successCallback(data);
            variables.setIsProcessing(false);
        },
        onError: (error, variables) => {
            variables.setApiError(error.response.data.message);
            variables.setIsProcessing(false);
        },
        onSettled: (data, error, variables) => {
            variables.setIsProcessing(false);
        },
    });
};
export default useVerifyAuthProviderCode;