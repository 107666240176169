import React from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';
import { BsCheckCircle, BsExclamationCircle, BsQuestionCircle, BsX } from "react-icons/bs";

import { getModalData, isModalActive, closeModal } from "../../store/slices/modal";

const modalName = "MESSAGE_MODAL";
const modalTitle = "Message";
const modalClass = "message";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const MessageModal = () => {

    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));
    const modalData = useSelector(getModalData(modalName));

    // const { closeOnEsc, closeOnOutsideClick, contentClass, bodyClass, icon, title, titleColor, topCloseBtn, content, bottomCloseBtn, buttons, buttonAlignment } = modalData;
    // const { className, label, style, onClick } = buttons - array;

    const afterOpenModal = () => { };

    const onCloseModal = () => {
        dispatch(closeModal(modalName));
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible && !!modalData}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={!!modalData?.closeOnEsc}
            shouldCloseOnOverlayClick={!!modalData?.closeOnOutsideClick}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content ${modalData?.contentClass ?? ''}`}>
                {modalData?.topCloseBtn &&
                    <button className="imm__modal_close_btn" onClick={onCloseModal}>
                        <BsX />
                    </button>
                }
                <div className={`imm__modal_body imm__${modalClass}__modal_body ${modalData?.bodyClass ?? ''}`}>
                    {
                        modalData?.icon === 'error'
                            ? <BsExclamationCircle className="color_error" />
                            : modalData?.icon === 'warning'
                                ? <BsExclamationCircle className="color_warning" />
                                : modalData?.icon === 'info'
                                    ? <BsQuestionCircle className="color_info" />
                                    : modalData?.icon === 'success'
                                        ? <BsCheckCircle className="color_success" />
                                        : null
                    }

                    <div className={`imm__${modalClass}__modal_right_content`}>
                        {modalData?.title &&
                            <h4 className={`${modalData?.titleColor ? `color_${modalData?.titleColor}` : ''}`}>{modalData?.title}</h4>
                        }
                        {
                            modalData?.content ?
                                <div className={`imm__${modalClass}__modal_content_sec`} dangerouslySetInnerHTML={{ __html: modalData.content }} />
                                : null
                        }
                    </div>
                </div>
                <div className={`imm__modal_footer imm__${modalClass}__modal_footer ${modalData?.buttonAlignment ? `imm__${modalClass}__modal_footer__${modalData?.buttonAlignment}` : ''} ${!modalData?.buttons?.length && !modalData?.bottomCloseBtn ? 'd_none' : ''}`}>
                    {!!modalData?.bottomCloseBtn &&
                        <button className={`imm__${modalClass}__modal_btn imm__${modalClass}__modal_bottom_close_btn`} onClick={onCloseModal}>Close</button>
                    }
                    {
                        modalData?.buttons?.length
                            ? modalData?.buttons.map((btn, index) =>
                                <button
                                    key={index}
                                    type="button"
                                    disabled={modalData?.isLoading}
                                    className={`imm__${modalClass}__modal_btn imm__${modalClass}__modal_btn__${btn.style} ${btn.className ?? ''}`}
                                    onClick={btn.onClick}
                                    dangerouslySetInnerHTML={{ __html: btn.label.replace(' ', '&nbsp;') }}
                                />
                            )
                            : ''
                    }
                </div>
            </div>
        </ReactModal>
    );
};

export default MessageModal;