import { useMutation } from "react-query";

import { sendActivationEmail } from "../../services/auth";

const useSendActivationEmail = () => {
    const mutationFn = async (data) => {
        const { email } = data;

        return await sendActivationEmail(email);
    };

    return useMutation(mutationFn, {
        onMutate: (variables) => { },
        onSuccess: (response, variables) => {
            if (response?.data) {
                if (variables?.successCallback) variables.successCallback();
            }
        },
        onError: (error, variables) => {
            if (variables?.errorCallback) variables.errorCallback(error?.response?.data);
        },
        onSettled: (data, error, variables) => {
            if (variables?.callback) variables.callback();
        }
    });
};

export default useSendActivationEmail;