import { useMutation } from "react-query";

import { createPaymentIntent } from "../../services/subscriptions";

const useCreatePaymentIntent = () => {
    const mutationFn = async (data) => {
        const { payload } = data;

        return await createPaymentIntent(payload);
    };

    return useMutation(mutationFn, {
        onMutate: (variables) => { },
        onSuccess: (response, variables) => {
            if (response) {
                if (variables?.successCallback) variables.successCallback(response);
            }
        },
        onError: (error, variables) => {
            if (variables?.errorCallback) variables.errorCallback(error?.response);
        },
        onSettled: (data, error, variables) => {
            if (variables?.callback) variables.callback(data);
        }
    });
};

export default useCreatePaymentIntent;