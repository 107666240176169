import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { getSessionData } from "../store/slices/global";
import { getTrackingPath } from "../helpers/tracking";

const ResultRoutes = () => {
    const location = useLocation();

    const sessionData = useSelector(getSessionData());

    return (
        sessionData && sessionData?.main?.primary_questionnaire?.status === 'finished'
            ? <Outlet />
            : <Navigate to={getTrackingPath('/auth')} state={{ from: location }} replace />
    );
};

export default ResultRoutes;