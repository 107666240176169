import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSessionData, setApplicantData } from "../../../store/slices/global";

import useSaveSpouse from "../../../hooks/applicants/useSaveSpouse";
import useResendSpouseInvitation from "../../../hooks/applicants/useResendSpouseInvitation";
import useUnlinkSpouse from "../../../hooks/applicants/useUnlinkSpouse";
import useCancelSpouseInvitation from "../../../hooks/applicants/useCancelSpouseInvitation";

const ManageSpouse = (props) => {
    const { modalData, connectionType, setConnectionType, onCloseModal } = props;

    const dispatch = useDispatch();
    const sessionData = useSelector(getSessionData());

    const { mutate: saveSpouse } = useSaveSpouse();
    const { mutate: resendSpouseInvitation } = useResendSpouseInvitation();
    const { mutate: cancelSpouseInvitation } = useCancelSpouseInvitation();
    const { mutate: unlinkSpouse } = useUnlinkSpouse();

    const [serverError, setServerError] = useState('');
    const [serverSuccess, setServerSuccess] = useState('');
    const [isProcessRunning, setIsProcessRunning] = useState(null);
    const [initialData, setInitialData] = useState({ firstName: "", lastName: "", email: "", phone: "", note: "" });
    const [formData, setFormData] = useState({ firstName: "", lastName: "", email: "", phone: "", note: "" });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (modalData) {
            const data = {
                firstName: modalData.spouse?.first_name ?? "",
                lastName: modalData.spouse?.last_name ?? "",
                email: modalData.spouse?.email ?? "",
                phone: modalData.spouse?.phone_number ?? "",
                note: "",
            };

            setFormData(data);
            setInitialData(data);

            if (modalData.spouse) {
                dispatch(setApplicantData({
                    applicantKey: 'spouse',
                    applicantData: {
                        ...sessionData.spouse,
                        isVerified: !!modalData.spouse?.verified,
                    },
                }));
            };
        };

        // eslint-disable-next-line
    }, [modalData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const onSaveSpouse = () => {
        setServerSuccess('');
        setServerError('');
        setErrors({});

        if (!formData.firstName?.trim()?.length) {
            setErrors({ firstName: 'First Name is required' });
            return;
        };

        if (!formData.lastName?.trim()?.length) {
            setErrors({ lastName: 'Last Name is required' });
            return;
        };

        if (modalData?.spouse?.email && !formData.email?.trim()?.length) {
            setErrors({ email: 'Email is required' });
            return;
        };

        if (formData.email?.trim()?.length && !/\S+@\S+\.\S+/.test(formData.email)) {
            setErrors({ email: 'Email address is invalid' });
            return;
        };

        setIsProcessRunning({ save: true });

        saveSpouse({
            payload: {
                type: 'save',
                first_name: formData.firstName,
                last_name: formData.lastName,
                email: formData.email,
                phone_number: formData.phone?.trim()?.length ? formData.phone : null,
                note: formData.note?.trim()?.length ? formData.note : null,
            },
            isExisting: sessionData?.spouse?.id ? true : false,
            onCloseModal,
            setServerSuccess,
            setServerError,
            setIsProcessRunning,
        });
    };

    const onInviteSpouse = () => {
        setServerSuccess('');
        setServerError('');
        setErrors({});

        if (!formData.firstName?.trim()?.length) {
            setErrors({ firstName: 'First Name is required' });
            return;
        };

        if (!formData.lastName?.trim()?.length) {
            setErrors({ lastName: 'Last Name is required' });
            return;
        };

        if (!formData.email?.trim()?.length) {
            setErrors({ email: 'Email is required' });
            return;
        };

        if (formData.email?.trim()?.length && !/\S+@\S+\.\S+/.test(formData.email)) {
            setErrors({ email: 'Email address is invalid' });
            return;
        };

        if (!formData.note?.trim()?.length) {
            setErrors({ note: 'Note is required' });
            return;
        };

        setIsProcessRunning({ invite: true });

        saveSpouse({
            payload: {
                type: 'invite',
                first_name: formData.firstName,
                last_name: formData.lastName,
                email: formData.email,
                phone_number: formData.phone?.trim()?.length ? formData.phone : null,
                note: formData.note,
            },
            isExisting: sessionData?.spouse?.id ? true : false,
            onCloseModal,
            setServerSuccess,
            setServerError,
            setIsProcessRunning,
        });
    };

    const onResendSpouseInvitation = () => {
        setServerSuccess('');
        setServerError('');
        setErrors({});

        if (!formData.note?.trim()?.length) {
            setErrors({ note: 'Note is required' });
            return;
        };

        setFormData({ ...initialData, note: formData.note });

        setIsProcessRunning({ resendInvite: true });

        resendSpouseInvitation({
            payload: {
                note: formData.note,
            },
            setServerSuccess,
            setServerError,
            setIsProcessRunning,
        });
    };

    const onCancelSpouseInvitation = () => {
        setServerSuccess('');
        setServerError('');
        setErrors({});

        setIsProcessRunning({ cancelInvite: true });

        cancelSpouseInvitation({
            onCloseModal,
            setServerSuccess,
            setServerError,
            setIsProcessRunning,
        });
    };

    const onUnlinkSpouse = () => {
        setServerSuccess('');
        setServerError('');
        setErrors({});

        setIsProcessRunning({ unlink: true });

        unlinkSpouse({
            onCloseModal,
            setServerSuccess,
            setServerError,
            setIsProcessRunning,
        });
    };

    return (
        <div className="imm__manage_connection_inner">

            <h3 className="dark_color">{modalData?.spouse?.first_name && modalData?.spouse?.last_name ? `${modalData.spouse.first_name} ${modalData.spouse.last_name}` : "Add your Spouse"}</h3>

            <br />
            <div className="imm__manage_connection_inner_box">

                {!modalData?.singleView &&
                    <div className="imm__manage_spouse__model_view_input_group">
                        <label>Type</label>
                        <select value={connectionType} onChange={(obj) => setConnectionType(obj.target.value)}>
                            <option value="spouse">Spouse</option>
                            <option value="organization">Employer</option>
                        </select>
                    </div>
                }

                <div className="imm__manage_spouse__model_view_input_group">
                    <label>FIRST NAME <strong className="color_error">*</strong></label>
                    <input
                        name="firstName"
                        placeholder="Enter first name"
                        type="text"
                        value={formData.firstName}
                        onChange={handleChange}
                    />
                    {errors.firstName && <h6 className='color_error'>{errors.firstName}</h6>}
                </div>

                <div className="imm__manage_spouse__model_view_input_group">
                    <label>LAST NAME <strong className="color_error">*</strong></label>
                    <input
                        name="lastName"
                        placeholder="Enter last name"
                        type="text"
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                    {errors.lastName && <h6 className='color_error'>{errors.lastName}</h6>}
                </div>

                <div className="imm__manage_spouse__model_view_input_group">
                    <label>EMAIL (Required for invitation)</label>
                    <input
                        name="email"
                        placeholder="Enter email"
                        type="text"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <h6 className='color_error'>{errors.email}</h6>}
                </div>

                <div className="imm__manage_spouse__model_view_input_group">
                    <label>PHONE</label>
                    <input
                        name="phone"
                        placeholder="Enter phone"
                        type="text"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    {errors.phone && <h6 className='color_error'>{errors.phone}</h6>}
                </div>

                <div className="imm__manage_spouse__model_view_input_group">
                    <label>NOTE FOR SPOUSE (Required for invitation)</label>
                    <textarea
                        name="note"
                        placeholder="Enter note"
                        type="text"
                        rows={4}
                        value={formData.note}
                        onChange={handleChange}
                    />
                    {errors.note && <h6 className='color_error'>{errors.note}</h6>}
                </div>

                {serverError && <h6 className='color_error text_center mt-3'>{serverError}</h6>}
                {serverSuccess && <h6 className='color_success text_center mt-3'>{serverSuccess}</h6>}

                <div className="imm__manage_spouse__actions_box">
                    <button
                        disabled={isProcessRunning}
                        className={`btn_block imm__regular__btn imm__btn__tertiary mt-3 ${isProcessRunning ? 'imm__btn__tertiary__disabled' : ''}`}
                        onClick={onCloseModal}
                    >
                        Discard
                    </button>
                    <button
                        disabled={isProcessRunning}
                        className={`btn_block imm__regular__btn imm__btn__primary mt-3 ${isProcessRunning ? 'imm__btn__primary__disabled' : ''}`}
                        onClick={() => onSaveSpouse()}
                    >
                        {
                            isProcessRunning?.save
                                ? <i className='imm__btn_loader' />
                                : modalData?.spouse ? 'Save' : 'Add Only'
                        }
                    </button>
                </div>
            </div>

            {(!sessionData?.spouse?.email || !sessionData?.spouse) &&
                <button
                    disabled={isProcessRunning}
                    className={`btn_block imm__regular__btn imm__btn__primary mt-3 ${isProcessRunning ? 'imm__btn__primary__disabled' : ''}`}
                    onClick={() => onInviteSpouse()}
                >
                    {
                        isProcessRunning?.invite
                            ? <i className='imm__btn_loader' />
                            : !sessionData?.spouse ? 'Add and Invite' : 'Invite Spouse'
                    }
                </button>
            }

            {sessionData?.spouse?.email && !sessionData?.spouse?.isVerified &&
                <>
                    <button
                        disabled={isProcessRunning}
                        className={`btn_block imm__regular__btn imm__btn__primary mt-3 ${isProcessRunning ? 'imm__btn__primary__disabled' : ''}`}
                        onClick={() => onResendSpouseInvitation()}
                    >
                        {
                            isProcessRunning?.resendInvite
                                ? <i className='imm__btn_loader' />
                                : `Resend Invite`
                        }
                    </button>
                    <button
                        disabled={isProcessRunning}
                        className={`btn_block imm__regular__btn imm__btn__tertiary mt-3 ${isProcessRunning ? 'imm__btn__tertiary__disabled' : ''}`}
                        onClick={() => onCancelSpouseInvitation()}
                    >
                        {
                            isProcessRunning?.cancelInvite
                                ? <i className='imm__btn_loader' />
                                : `Cancel Invite`
                        }
                    </button>
                </>
            }

            {sessionData?.spouse?.email && sessionData?.spouse?.isVerified &&
                <button
                    disabled={isProcessRunning}
                    className={`btn_block imm__regular__btn imm__btn__tertiary mt-3 ${isProcessRunning ? 'imm__btn__tertiary__disabled' : ''}`}
                    onClick={() => onUnlinkSpouse()}
                >
                    {
                        isProcessRunning?.unlink
                            ? <i className='imm__btn_loader' />
                            : 'Unlink Spouse'
                    }
                </button>
            }
        </div>
    );
};

export default ManageSpouse;