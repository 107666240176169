export const authStages = {
    SIGNIN_INFO: 'SIGNIN_INFO',
    SIGNUP_INFO: 'SIGNUP_INFO',
    ACTIVATION_LINK_SENT: 'ACTIVATION_LINK_SENT',
    SEND_RESET_PASSWORD_LINK: 'SEND_RESET_PASSWORD_LINK',
    RESET_PASSWORD_INFO: 'RESET_PASSWORD_INFO'
};

export const oldStages = {
    LANDING: 'LANDING',
    SIGNIN_INFO: 'SIGNIN_INFO',
    SIGNUP_INFO: 'SIGNUP_INFO',
    VERIFICATION: 'VERIFICATION',
    SEND_RESET_PASSWORD_LINK: 'SEND_RESET_PASSWORD_LINK',
    RESET_PASSWORD: 'RESET_PASSWORD',
}