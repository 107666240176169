import { useQuery } from "react-query";

import { getConnections } from "../../services/invitations";

const useGetConnections = (isEnabled) => {

    const queryFn = async () => {
        return await getConnections();
    };

    return useQuery({
        queryKey: ['connections'],
        queryFn,
        enabled: isEnabled === true,
        retry: 0,
    });
};

export default useGetConnections;