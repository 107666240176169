import { useQuery } from "react-query";
import { getPackages } from "../../services/subscriptions";

const useGetPackages = (fetchPackages) => {
    return useQuery('packages', getPackages, {
        enabled: fetchPackages,
        refetchOnWindowFocus: false,
    });
};

export default useGetPackages;