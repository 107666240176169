import React from 'react'
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { BsX } from 'react-icons/bs';

import { closeModal, isModalActive } from '../../store/slices/modal';

import Packages from '../sub_components/Packages';

const modalName = "PACKAGES_MODAL";
const modalTitle = "Packages";
const modalClass = "packages";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const PackagesModal = () => {
    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));

    const onCloseModal = () => {
        dispatch(closeModal(modalName));
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <button className="close_btn" onClick={onCloseModal}>
                    <BsX />
                </button>

                <Packages />
            </div>
        </ReactModal>
    )
}

export default PackagesModal;