import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import Hotjar from '@hotjar/browser';
import { Helmet } from "react-helmet";

const isProd = () => {
    return process.env.REACT_APP_APP_ENV === 'PRODUCTION';
};

export const initGA4 = () => {
    if (isProd()) {
        ReactGA.initialize([
            { trackingId: process.env.REACT_APP_GA4_MEASUREMENT_ID },
        ]);
    };
};

export const initGTM = () => {
    if (isProd()) {
        TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
    };
};

export const initHotjar = () => {
    if (isProd()) {
        Hotjar.init(process.env.REACT_APP_HOTJAR_SITE_ID, process.env.REACT_APP_HOTJAR_VERSION);
    };
};

export const MetaPixel = () => {
    if (!isProd()) {
        return null;
    };

    return (
        <Helmet>
            <script id="facebook-pixel-script">
                {
                    `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', ${process.env.REACT_APP_META_PIXEL_ID});
fbq('track', 'PageView');`
                }
            </script>
            <noscript id="facebook-pixel-image">
                {
                    `<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=${process.env.REACT_APP_META_PIXEL_ID}&ev=PageView&noscript=1"
/>`
                }
            </noscript>
        </Helmet>
    );
};

export const triggerEvent = (args) => {
    if (isProd()) {
        const dataLayerObj = Object.keys(args).reduce((acc, key) => {
            if (key !== 'name') {
                acc[key] = args[key];
            };

            if (key === 'email') {
                acc[key] = args[key].replace('@', '[at]').replace('.', '[dot]');
            };

            return acc;
        }, { event: args.name });

        if (!window.dataLayer) window.dataLayer = [];
        window.dataLayer.push(dataLayerObj);
    };
};

export const getTrackingPath = (path) => {
    var fbclid = new URLSearchParams(window.location.search).get('fbclid');
    var gclid = new URLSearchParams(window.location.search).get('gclid');

    if (!fbclid && !gclid) {
        return path;
    };

    if (path.includes('?')) {
        if (gclid) {
            return `${path}&gclid=${gclid}`;
        } else if (fbclid) {
            return `${path}&fbclid=${fbclid}`;
        };
    } else {
        if (gclid) {
            return `${path}?gclid=${gclid}`;
        } else if (fbclid) {
            return `${path}?fbclid=${fbclid}`;
        };
    };
};