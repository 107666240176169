import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import { saveApplicantDetail } from "../../services/applicants";

import useUpdateStatusManagerInfoInCRM from "./useUpdateStatusManagerInfoInCRM";

import { getCurrentApplicantKey, getSessionData } from "../../store/slices/global";

import { triggerEvent } from "../../helpers/tracking";

const useSaveApplicantDetail = () => {
    const queryClient = useQueryClient();

    const currentApplicantKey = useSelector(getCurrentApplicantKey());
    const sessionData = useSelector(getSessionData());

    const { mutate: updateStatusManagerInfoInCRM } = useUpdateStatusManagerInfoInCRM();

    const mutationFn = async (payload) => {
        const { applicantKey, data } = payload;

        return await saveApplicantDetail(applicantKey, data);
    };

    return useMutation({
        mutationFn,
        onMutate: (variables) => {
            variables.setIsProcessing(true);
            variables.setApiError(null);
        },
        onSuccess: (response, variables) => {
            if (response.statusCode === 200) {
                queryClient.invalidateQueries(['applicant-data', variables.applicantKey]);

                if (!!variables?.data?.sin_expiry) {
                    triggerEvent({
                        name: 'status_manager_sin_date_input',
                        action: 'api_response',
                        category: 'Status Manager',
                        email: sessionData.email,
                        user_id: sessionData.user_id,
                        profile_type: currentApplicantKey,
                    });
                };

                updateStatusManagerInfoInCRM();
            }
            variables.setIsProcessing(false);
        },
        onError: (error, variables) => {
            variables.setIsProcessing(false);
            variables.setApiError(error?.response?.data?.message);
        },
        onSettled: (data, error, variables) => {
            variables.setIsProcessing(false);
        }
    });
};

export default useSaveApplicantDetail;