import React from 'react';
import { BiLoaderAlt } from "react-icons/bi";
import styled from 'styled-components';

import './index.css';

import { iconAssets } from '../../../../constants/assets_url';

const LoaderWrap = styled.div`
  ${({ overlay }) => overlay ? 'position: fixed;' : ''}
  height: 100vh;
  min-height: 300px;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderBody = styled.div`
  border-radius: 10px;
  background: white;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingIcon = styled(BiLoaderAlt)`
  animation: spin-anim 1s linear infinite;
  -webkit-animation: spin-anim 1s linear infinite;
  -moz-animation: spin-anim 1s linear infinite;
`;

const LoaderIconBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingImg = styled.img`
  animation: spin-anim 2s linear infinite;
  -webkit-animation: spin-anim 2s linear infinite;
  -moz-animation: spin-anim 2s linear infinite;
  max-width: 50%;
  max-height: 50%;
  -o-object-fit: contain;
  object-fit: contain;
`;

const PageLoader = ({ overlay }) => {
  return (
    <LoaderWrap overlay={overlay}>
      <LoaderBody>
        <LoaderIconBox>
          <LoadingImg src={iconAssets.PlaneIcon} />
        </LoaderIconBox>
        {/* <LoadingIcon size="60px" color="#3F62AC" /> */}
      </LoaderBody>
    </LoaderWrap>
  )
}

export default PageLoader;