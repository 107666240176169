import React, { useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

import { BsX } from "react-icons/bs";

import { isModalActive, closeModal, getModalData } from "../../store/slices/modal";
import { getSessionData, setSubscription } from "../../store/slices/global";

import useCreateSubscription from "../../hooks/subscriptions/useCreateSubscription";
import useVerifyPromoCode from "../../hooks/subscriptions/useVerifyPromoCode";
import useUpdatePaymentIntent from "../../hooks/subscriptions/useUpdatePaymentIntent";

import { getTrackingPath, triggerEvent } from "../../helpers/tracking";

const modalName = "PAYMENT_MODAL";
const modalTitle = "Payment";
const modalClass = "payment";

const topupLvl = 0;

const PaymentForm = ({ paymentOptions, selectedPackage }) => {

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const sessionData = useSelector(getSessionData());

    const [isProcessRunning, setIsProcessRunning] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeError, setPromoCodeError] = useState(null);
    const [promotion, setPromotion] = useState(null);

    const { mutate: createSubscription } = useCreateSubscription();
    const { mutate: verifyPromoCode } = useVerifyPromoCode();
    const { mutate: updatePaymentIntent } = useUpdatePaymentIntent();

    const onApplyPromoCode = async (e) => {
        e.preventDefault();

        if (!promoCode || !selectedPackage) return;

        triggerEvent({
            name: 'select_promotion',
            action: 'click',
            category: 'Subscription',
            email: sessionData.email,
            user_id: sessionData.user_id,
            promo_code: promoCode,
        });

        setIsProcessRunning(true);
        setPromoCodeError(null);
        verifyPromoCode({
            payload: {
                packageId: selectedPackage.id,
                code: promoCode
            },
            successCallback: (promotion) => {
                triggerEvent({
                    name: 'promotion_applied',
                    action: 'api_response',
                    category: 'Subscription',
                    email: sessionData.email,
                    user_id: sessionData.user_id,
                    promo_code: promotion.code,
                    promo_amount: promotion.amount,
                    promo_consults: promotion.consults,
                    promo_months: promotion.subscription_months,
                });

                setPromotion(promotion);
            },
            errorCallback: (err) => {
                setPromotion(null);
                const errorMsg = err?.data?.message;
                if (errorMsg?.length) {
                    if (errorMsg.includes('Promotion does not exist')) {
                        setPromoCodeError('Invalid promo code!');
                    } else if (errorMsg.includes('Promotion is expired')) {
                        setPromoCodeError('Promo code is expired!');
                    } else if (errorMsg.includes('Promotion is not active')) {
                        setPromoCodeError('Promo code is not active!');
                    } else if (errorMsg.includes('Promotion has reached its maximum usage')) {
                        setPromoCodeError('Promo code is expired!');
                    } else {
                        setPromoCodeError('Error applying promo code!');
                    }
                } else {
                    setPromoCodeError('Error applying promo code!');
                }
            },
            callback: () => {
                setIsProcessRunning(false);
            }
        });
    };

    const onRemovePromoCode = (e) => {
        e.preventDefault();

        setPromotion(null);
        setPromoCodeError(null);
        setPromoCode('');
    };

    const onCreateSubscription = async (payment_ref) => {
        triggerEvent({
            name: 'purchase',
            action: 'api_response',
            category: 'Subscription',
            email: sessionData.email,
            user_id: sessionData.user_id,
            payment_ref: payment_ref ?? 'N/A',
            value: !payment_ref ? 0 : parseFloat(promotion?.amount) > 0 ? Number(promotion.amount) : Number(selectedPackage.amount),
            currency: 'CAD',
            items: [{
                item_id: selectedPackage.id,
                item_name: selectedPackage.subscription_months > 1 ? 'annual' : 'monthly',
                quantity: 1,
                price: Number(selectedPackage.amount),
            }],
            pkg_amount: Number(selectedPackage.amount),
            pkg_consults: selectedPackage.consults,
            pkg_months: selectedPackage.subscription_months,
            coupon: promotion?.code ?? 'N/A',
            promo_amount: promotion?.amount ?? 'N/A',
            promo_consults: promotion?.consults ?? 'N/A',
            promo_months: promotion?.subscription_months ?? 'N/A',
            payment_method: payment_ref ? 'card' : 'N/A',
            fbclid: new URLSearchParams(window.location.search).get('fbclid'),
            gclid: new URLSearchParams(window.location.search).get('gclid'),
        });

        createSubscription({
            payload: {
                package_id: selectedPackage?.id ?? null,
                payment_ref: payment_ref ?? null,
                promotion_id: promotion?.id ?? null
            },
            successCallback: async (subscription) => {
                if (subscription) {
                    dispatch(setSubscription(subscription));
                    window.location.href = getTrackingPath('/payment-confirmed');
                }
            },
            errorCallback: (err) => {
                alert('Error creating subscription. Please do not make the payment again.');
            }
        });
    };

    const onMakePayment = async (e) => {
        e.preventDefault();

        if (!elements || !paymentOptions?.clientSecret) return;

        triggerEvent({
            name: 'add_payment_info',
            action: 'input',
            category: 'Subscription',
            email: sessionData.email,
            user_id: sessionData.user_id,
            value: parseFloat(promotion?.amount) > 0 ? Number(promotion.amount) : Number(selectedPackage.amount),
            currency: 'CAD',
            coupon: promotion?.code ?? 'N/A',
            payment_type: 'card',
            items: [{
                item_id: selectedPackage.id,
                item_name: selectedPackage.subscription_months > 1 ? 'annual' : 'monthly',
                quantity: 1,
                price: Number(selectedPackage.amount),
            }],
            pkg_amount: Number(selectedPackage.amount),
            pkg_consults: selectedPackage.consults,
            pkg_months: selectedPackage.subscription_months,
        });

        const { error: submitError } = elements.submit();
        if (submitError) {
            console.error(submitError);
            return;
        };

        setIsProcessRunning(true);

        let clientSecret = paymentOptions.clientSecret;

        updatePaymentIntent({
            payload: {
                paymentIntentId: paymentOptions.paymentIntentId,
                amount: parseFloat(promotion?.amount) > 0 ? Number(promotion.amount) : Number(selectedPackage.amount),
            },
            successCallback: async (paymentIntent) => {
                if (paymentIntent) {
                    clientSecret = paymentIntent.client_secret;
                } else {
                    console.error('Error creating stripe session');
                    return setIsProcessRunning(false);
                }
            },
            errorCallback: (err) => {
                console.error('Error creating stripe session');
                return setIsProcessRunning(false);
            }
        });

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            clientSecret: clientSecret,
            redirect: 'if_required'
        })

        if (!paymentIntent) {
            console.error(error);
        } else {
            onCreateSubscription(paymentIntent.id);
        }

        return setIsProcessRunning(false);
    };

    const onSubscribe = async (e) => {
        e.preventDefault();
        setIsProcessRunning(true);
        onCreateSubscription(null);
        return setIsProcessRunning(false);
    };

    return (
        <>
            <div className="imm__payment_promo_code_container pb-4">
                <div className="imm__payment_promo_code">
                    <input
                        placeholder="Enter promo code"
                        value={promoCode}
                        maxLength={20}
                        onChange={(e) => setPromoCode(e.target.value.toUpperCase().replace(/\s/g, ''))}
                    />
                    <button
                        className={`imm__regular__btn imm__btn__primary imm__payment_apply_promo_code_btn ${promotion ? 'imm__payment_remove_promo_code_btn' : ''}`}
                        onClick={!promotion ? onApplyPromoCode : onRemovePromoCode}
                        disabled={!promoCode}
                    >
                        {
                            isProcessRunning
                                ? <i className='imm__btn_loader' />
                                : promotion
                                    ? 'Remove'
                                    : 'Apply'
                        }
                    </button>
                </div>
                <div className="imm__payment_promo_code_responses">
                    <p className="mice">
                        {
                            promotion
                                ? <span className="color_success">Promo code applied!</span>
                                : promoCodeError
                                    ? <span className="color_error">{promoCodeError}</span>
                                    : <span className="color_disabled">Enter a promo code to get a discount!</span>
                        }
                    </p>
                </div>
            </div>

            <div className="imm__payment_package_info">
                <h5 className="pb-1 color_primary">Package Summary</h5>
                <div className="imm__payment_package_info_inner">
                    <div className="imm__payment_package_info_item">
                        <p className="mice">Amount:</p>
                        {
                            promotion?.amount !== null && promotion?.amount !== undefined
                                ? <p className="mice color_success">
                                    <strong>${promotion?.amount} CAD</strong>&nbsp;
                                    <span className="original_data">${selectedPackage?.amount} CAD</span>
                                </p>
                                : <p className="mice">
                                    <strong>${selectedPackage?.amount} CAD</strong>
                                </p>
                        }
                    </div>
                    {selectedPackage?.subscription_months > 0 &&
                        <div className="imm__payment_package_info_item">
                            <p className="mice">Subscription validity:</p>
                            {
                                promotion?.subscription_months !== null && promotion?.subscription_months !== undefined
                                    ? <p className="mice color_success">
                                        <strong>{promotion?.subscription_months} months</strong>&nbsp;
                                        <span className="original_data">{selectedPackage?.subscription_months} month{selectedPackage?.subscription_months > 1 ? 's' : ''}</span>
                                    </p>
                                    : <p className="mice">
                                        <strong>{selectedPackage?.subscription_months} month{selectedPackage?.subscription_months > 1 ? 's' : ''}</strong>
                                    </p>
                            }
                        </div>
                    }
                </div>
            </div>

            {
                promotion?.amount !== null && promotion?.amount !== undefined && parseFloat(promotion?.amount) <= 0
                    ? null
                    : <>
                        <h5 className="pb-3 color_primary">Cardholder Information</h5>
                        <PaymentElement />
                    </>
            }

            {
                promotion?.amount !== null && promotion?.amount !== undefined && parseFloat(promotion?.amount) <= 0
                    ? <button
                        className="mt-5 btn_block imm__regular__btn imm__btn__primary"
                        disabled={isProcessRunning}
                        onClick={onSubscribe}
                    >
                        {
                            isProcessRunning
                                ? <i className='imm__btn_loader' />
                                : <span>Subscribe now!</span>
                        }
                    </button>
                    : !!paymentOptions && !!selectedPackage
                        ? <button
                            className="mt-5 btn_block imm__regular__btn imm__btn__primary"
                            onClick={onMakePayment}
                            disabled={!stripe || !elements || isProcessRunning}
                        >
                            {
                                isProcessRunning
                                    ? <i className='imm__btn_loader' />
                                    : <span>
                                        Confirm&nbsp;payment&nbsp;of&nbsp;$
                                        {parseFloat(promotion?.amount) > 0 ? promotion.amount : selectedPackage.amount}
                                    </span>
                            }
                        </button>
                        : null
            }
        </>
    );
};

ReactModal.setAppElement('#root');
const PaymentModal = () => {

    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));
    const modalData = useSelector(getModalData(modalName));

    // const { package, packageOptions } = modalData;

    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

    const afterOpenModal = () => { };

    const onCloseModal = () => {
        dispatch(closeModal(modalName));
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <div className="imm__payment_inner_wrap">
                    <button className="close_btn" onClick={onCloseModal}>
                        <BsX />
                    </button>

                    <h3 className="text_center color_primary pt-2 pb-4">Get Immproved Plus</h3>

                    {
                        !!modalData?.paymentOptions &&
                        <Elements stripe={stripePromise} options={modalData.paymentOptions}>
                            <PaymentForm
                                paymentOptions={modalData.paymentOptions}
                                selectedPackage={modalData?.package}
                            />
                        </Elements>
                    }

                    <p className="mice text_center pt-4 pb-3">Money-back guarantee</p>
                    <button className="btn_block imm__regular__btn imm__btn__secondary" onClick={onCloseModal}>Cancel</button>

                </div>
            </div>
        </ReactModal>
    );
};

export default PaymentModal;