import api from ".";

import cookiesHelper from "../helpers/cookies";

export const getAuthProviders = async (data) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`auth-providers/${data.userEmail}`);
        return res.data;
    } catch (error) {
        throw error;
    }
}

export const registerAuthProvider = async (data) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`auth-providers/register`, data);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const generateAuthProviderCode = async (data) => {
    // const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    // api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const { type, userEmail } = data;
    try {
        const res = await api.patch(`auth-providers/${type}/generate-code/${encodeURIComponent(userEmail)}`);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const verifyAuthProviderCode = async (data) => {
    // const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    // api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const { type, code } = data;
    try {
        const res = await api.post(`auth-providers/verify/${encodeURIComponent(data.userEmail)}`, {
            code,
            type
        });
        return res.data;
    } catch (error) {
        throw error;
    }
};

