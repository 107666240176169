class CookiesHelper {
    getCookie(name) {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');

        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return null;
    }

    setCookie(name, value, exp) {
        const d = new Date();
        d.setTime(d.getTime() + exp);

        const expires = "expires=" + d.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    removeCookie(name) {
        if (this.getCookie(name)) {
            const d = new Date();
            d.setTime(d.getTime() - (24 * 60 * 60 * 1000));

            const expires = "expires=" + d.toUTCString();
            document.cookie = name + "=;" + expires + ";path=/";
        }
    }
}

const cookiesHelper = new CookiesHelper();

export default cookiesHelper;