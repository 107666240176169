import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';

import { BsX } from "react-icons/bs";

import { isModalActive, closeModal, getModalData } from "../../store/slices/modal";

import ManageSpouse from "./views/ManageSpouse";
import AddOrganization from "./views/AddOrganization";

const modalName = "MANAGE_CONNECTION";
const modalTitle = "Manage Connection";
const modalClass = "manage_connection";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const ManageConnectionModal = () => {
    const dispatch = useDispatch();

    const isModalVisible = useSelector(isModalActive(modalName));
    const modalData = useSelector(getModalData(modalName));

    const [connectionType, setConnectionType] = useState(null);

    const onCloseModal = () => {
        setConnectionType(null);
        dispatch(closeModal(modalName));
    };

    useEffect(() => {
        if (modalData?.connectionType) {
            setConnectionType(modalData.connectionType)
        };
    }, [modalData]);

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <div className="imm__manage_connection_inner_wrap">
                    <button className="close_btn" onClick={onCloseModal}>
                        <BsX />
                    </button>

                    <div className="imm__manage_connection__model_view">
                        {connectionType === "spouse" && <ManageSpouse modalData={modalData} connectionType={connectionType} setConnectionType={setConnectionType} onCloseModal={onCloseModal} />}
                        {connectionType === "organization" && <AddOrganization modalData={modalData} connectionType={connectionType} setConnectionType={setConnectionType} onCloseModal={onCloseModal} />}
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default ManageConnectionModal;