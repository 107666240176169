import cookiesHelper from "./cookies";

class AuthHelper {
    isAuthTokenValid = () => {
        const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);

        if (token) {
            try {
                const tokenPayload = JSON.parse(atob(token.split('.')[1]));
                const tokenExp = tokenPayload.exp;
                const dateNow = new Date();

                if (tokenExp < dateNow.getTime() / 1000) {
                    return { result: false, reason: 'expired' };
                } else {
                    return { result: true, reason: 'valid' };
                }
            } catch (e) {
                return { result: false, reason: 'invalid' };
            }
        } else {
            return { result: false, reason: 'not_found' };
        }
    };
}

const authHelper = new AuthHelper();

export default authHelper;