import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { copyStatusInfoFromOrganization } from "../../services/applicants";

import { closeModal, openModal } from "../../store/slices/modal";
import { getTrackingPath } from "../../helpers/tracking";

const useCopyStatusInfoFromOrganization = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const mutationFn = async (data) => {
        return await copyStatusInfoFromOrganization(data.organizationId);
    };

    return useMutation(mutationFn, {
        onMutate: (variables) => { },
        onSuccess: (response, variables) => {
            if (response) {
                dispatch(openModal({
                    name: 'MESSAGE_MODAL',
                    data: {
                        closeOnEsc: true,
                        closeOnOutsideClick: true,
                        icon: 'success',
                        title: 'Status information updated!',
                        titleColor: 'primary',
                        content: 'Your status information has been updated successfully. You can now view your status information in status manager.',
                        buttons: [
                            {
                                label: 'Status Manager',
                                style: 'primary',
                                onClick: () => {
                                    dispatch(closeModal('MESSAGE_MODAL'))
                                    setTimeout(() => {
                                        navigate(getTrackingPath('/status-manager'))
                                    }, 500);
                                }
                            },
                            { label: 'Close', style: 'info', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                        ]
                    },
                }));
            };
        },
        onError: (error, variables) => {
            dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    closeOnEsc: true,
                    closeOnOutsideClick: true,
                    icon: 'error',
                    title: 'Status Update Failed',
                    titleColor: 'error',
                    content: 'An error occurred while updating your status information. Please try again later.',
                    buttons: [
                        { label: 'Close', style: 'info', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                    ]
                },
            }));
        },
        onSettled: (data, error, variables) => { }
    });
};

export default useCopyStatusInfoFromOrganization;